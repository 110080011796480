<template>
  <div class="table-card-row">
    <div
      class="table-card-row__item"
      v-bind:key="sindex + '' + settingsItem.computeFn(data[settingsItem.key])"
      v-for="(settingsItem, sindex) in settings.header"
      :style="{
        color: settingsItem.bodyColor,
        flex: settingsItem.columnWidth,
        whiteSpace: settingsItem.whiteSpace,
        fontSize: settingsItem.fontSize,
        textAlign: settingsItem.textAlign,
      }"
    >
      <div class="item-wrapper">
        <div
          class="trend-type"
          v-if="settingsItem.type && settingsItem.type === 'trend'"
        >
          <span>{{
            settingsItem.computeFn(data[settingsItem.key]).value
          }}</span>
          <font-awesome-icon
            v-if="
              getTrendIcon(settingsItem.computeFn(data[settingsItem.key]).trend)
            "
            :icon="
              getTrendIcon(settingsItem.computeFn(data[settingsItem.key]).trend)
            "
          />
        </div>

        <div
          class="link-type"
          v-else-if="settingsItem.type && settingsItem.type === 'link'"
        >
          <span>{{ getLinkLable(settingsItem, data) }}</span>
          <router-link
            :to="settingsItem.computeFn(data[settingsItem.key]).link"
            :style="{ color: settingsItem.bodyColor }"
          >
            <font-awesome-icon icon="custom-external-link-alt" />
          </router-link>
        </div>

        <div
          class="tags-type"
          v-else-if="settingsItem.type && settingsItem.type === 'tags'"
        >
          <TagButton
            :label="tagItem"
            :key="tagIdx"
            v-for="(tagItem, tagIdx) in settingsItem.computeFn(
              data[settingsItem.key]
            )"
          />
        </div>

        <div
          class="bg-label-type"
          v-else-if="settingsItem.type && settingsItem.type === 'bg-label'"
        >
          <div
            class="bg-label"
            :style="settingsItem.computeFn(data[settingsItem.key]).style"
          >
            {{ settingsItem.computeFn(data[settingsItem.key]).label }}
          </div>
        </div>

        <div
          class="label-action-type"
          v-else-if="settingsItem.type && settingsItem.type === 'label-action'"
        >
          <div
            @click="
              settingsItem.action(
                settingsItem.computeFn(data[settingsItem.key])
              )
            "
          >
            {{ settingsItem.computeFn(data[settingsItem.key]) }}
          </div>
        </div>

        <div
          v-else
          v-html="settingsItem.computeFn(data[settingsItem.key])"
        ></div>
      </div>
    </div>
    <div
      class="table-card-row__sort"
      v-if="settings.sortable && dataArray.length > 1"
      :style="settings.sortStyles ? settings.sortStyles : {}"
    >
      <font-awesome-icon
        class="chevron-up"
        icon="chevron-up"
        @click="$emit('sort-up', rowIndex)"
        v-show="rowIndex > 0"
      />
      <font-awesome-icon
        icon="chevron-down"
        class="chevron-down"
        @click="$emit('sort-down', rowIndex)"
        v-show="dataArray.length - 1 > rowIndex"
      />
    </div>
    <div class="table-card-row__btn" :style="{ flex: settings.actionsWidth }">
      <div
        class="btn-wrapper"
        v-for="(btnItem, btnIdx) in settings.actions"
        :key="btnIdx"
        @click="btnItem.action({ ...data, index: rowIndex })"
        v-show="
          btnItem.show === undefined || (btnItem.show && btnItem.show(data))
        "
      >
        <template v-if="btnItem.type === 'button' || btnItem.variant">
          <Button
            :variant="btnItem.variant"
            :label="btnLabel(btnItem, data)"
            :styles="btnItem.styles"
          />
        </template>

        <template v-if="btnItem.type === 'icon' || btnItem.icon">
          <font-awesome-icon :icon="btnItem.icon" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import TagButton from "@/components/Buttons/TagButton.vue";

import { sortUp } from "@/services/sorting";
import { sortDown } from "@/services/sorting";

export default {
  name: "TableCardRow",
  components: {
    Button,
    TagButton,
  },
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({
        header: [],
        actions: [],
      }),
    },
    data: {
      type: Object,
      default: () => {},
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    sortDownMethod(index) {
      sortDown(this.dataArray, index, this.$set);
    },
    sortUpMethod(index) {
      sortUp(this.dataArray, index, this.$set);
    },
    getLinkLable(settings, data) {
      if (settings.computeFn(data[settings.key]).label) {
        return settings.computeFn(data[settings.key]).label;
      } else if (settings.computeFn(data[settings.key]).link) {
        return settings.computeFn(data[settings.key]).link;
      } else return settings.computeFn(data[settings.key]);
    },
    getTrendIcon(trend) {
      if (trend === 1) return "arrow-up";
      if (trend === -1) return "arrow-down";
      if (trend === 0) return "";
    },
    btnLabel(btn, data) {
      if (
        typeof btn.labelFn !== "undefined" &&
        typeof btn.dynamicIdentifier !== "undefined" &&
        data[btn.dynamicIdentifier]
      ) {
        return btn.labelFn(data[btn.dynamicIdentifier]);
      }

      return btn.label;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.table-card-row {
  display: flex;
  align-items: center;
  gap: var(--gap);
  border-bottom: 1px solid $light_gray;
  padding: 15px 25px;
  font-size: 0.875rem;

  &__item {
    .item-wrapper {
      .trend-type {
        svg {
          color: $gray_text;
          margin-left: 10px;
        }
      }

      .link-type {
        svg {
          color: $gray_text;
          margin-left: 10px;
        }
      }

      .bg-label-type {
        .bg-label {
          display: inline-block;
          padding: 8px 15px;
          border-radius: 4px;
          font-weight: 600;
          color: $white;
        }
      }
    }
  }

  &__sort {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
      transition: all 0.3s ease 0s;

      &:hover {
        filter: brightness(70%);
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn-wrapper {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-left: 15px;
      }

      svg {
        height: 25px;
        width: 25px;
        color: $gray_text;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          filter: brightness(85%);
        }
      }
    }
  }
}
</style>
