<template>
  <BaseCard
    class="table-card"
    padding="0"
    border-radius="0"
    :style="stylesList"
  >
    <div class="table-card__thead">
      <div
        class="table-card__thead__column"
        v-bind:key="index"
        v-for="(item, index) in settings.header"
        v-bind:style="{ color: item.headerColor, flex: item.columnWidth }"
      >
        {{ item.headerLabel }}
      </div>
      <div
        class="table-card__thead__btn"
        :style="{ flex: settings.actionsWidth }"
      >
        {{ settings.actionsHeaderLabel }}
      </div>
    </div>
    <div class="table-card__tbody">
      <TableCardRow
        v-for="(dataItem, dindex) in dataArray"
        :key="dindex"
        :row-index="dindex"
        :data-array="dataArray"
        :settings="settings"
        :data="dataItem"
        :class="{ last: dindex === Object.keys(dataArray).length - 1 }"
        @sort-up="sortUp"
        @sort-down="sortDown"
      />
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import TableCardRow from "@/components/Table/TableCardRow";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronUp,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { customExternalLinkAlt } from "@/assets/icons";
library.add(
  faChevronDown,
  faChevronUp,
  faArrowUp,
  faArrowDown,
  customExternalLinkAlt
);

export default {
  name: "TableCard",
  components: {
    BaseCard,
    TableCardRow,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({
        header: [],
        actions: [],
      }),
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    stylesList() {
      let output = {
        "--gap": "20px",
        "--min-table-width": "300px",
      };
      if (this.settings.tableGap) output["--gap"] = this.settings.tableGap;
      if (this.settings.minTableWidth)
        output["--min-table-width"] = this.settings.minTableWidth;

      return output;
    },
  },
  methods: {
    sortUp(val) {
      this.$emit("sort-up", val);
    },
    sortDown(val) {
      this.$emit("sort-down", val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.table-card {
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    height: $scrollbar_height;
  }
  &::-webkit-scrollbar-track {
    background: $light_gray;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $navy;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

  &__thead,
  &__tbody {
    min-width: var(--min-table-width);
  }

  &__thead {
    display: flex;
    align-items: center;
    gap: var(--gap);
    border-bottom: 1px solid $light_gray;
    padding: 15px 25px;
    font-weight: 600;
  }

  // &__tbody {}
}
</style>
